var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label
      ? _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "input-wrap" },
      [
        _c("i-mask", {
          staticClass: "form-input",
          class: _vm.style,
          attrs: {
            type: "text",
            placeholder: _vm.placeholder,
            unmask: _vm.unmask,
            mask: _vm.mask
          },
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            },
            keyup: function($event) {
              return _vm.$emit("keyup", $event)
            },
            keydown: function($event) {
              return _vm.$emit("keydown", $event)
            },
            keypress: function($event) {
              return _vm.$emit("keypress", $event)
            },
            change: function($event) {
              return _vm.$emit("change", $event)
            },
            blur: function($event) {
              return _vm.$emit("blur", $event)
            },
            focus: function($event) {
              return _vm.$emit("focus", $event)
            }
          },
          model: {
            value: _vm._value,
            callback: function($$v) {
              _vm._value = $$v
            },
            expression: "_value"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.help !== undefined
      ? _c("span", { staticClass: "form-help" }, [_vm._v(_vm._s(_vm.help))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }